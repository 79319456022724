import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
$(($) => {
  if (!$('.sd-calculator').length) return;

  // get calculator cookie
  const getCalculatorCookieData = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  };

  // check calculator cookies set
  const hasCalculatorCookieSet = (calculatorId) => {
    const formSubmitted = getCalculatorCookieData(`${calculatorId}_form_submitted`);
    const formSubmittedId = getCalculatorCookieData(`${calculatorId}_form_submitted_id`);
    return formSubmitted && formSubmittedId;
  }

  // get submitting form data
  const getFormData = (calculatorId, form) => {
    let data = {};
    switch (calculatorId) {
      case "upsell_impact_calculator":
        data = {
          "average_basket_size": form.find('input[name="average_basket_size"]').val(),
          "average_service_time_in_seconds": form.find('input[name="average_service_time_in_seconds"]').val(),
          "estimated_average_upsell_value": form.find('input[name="estimated_average_upsell_value"]').val(),
          "cars_per_month_per_location": form.find('input[name="cars_per_month_per_location"]').val(),
          "total_number_of_locations": form.find('input[name="total_number_of_locations"]').val(),
        }
        break;
      case "employee_assist_calculator":
        data = {
          "current_turnover_rate": form.find('input[name="current_turnover_rate"]').val(),
          "average_cost_of_turnover_per_employee_year": form.find('input[name="average_cost_of_turnover_per_employee_year"]').val()
        }
        break;
      case "smart_answering_calculator":
        data = {
          "average_daily_phone_calls": form.find('input[name="average_daily_phone_calls"]').val(),
          "estimated_calls_not_picked_up": form.find('input[name="estimated_calls_not_picked_up"]').val(),
          "dedicated_person_on_the_phone": form.find('select[name="dedicated_person_on_the_phone"]').val(),
          "hourly_wage_of_staff_member": form.find('input[name="hourly_wage_of_staff_member"]').val(),
          "percentage_of_hourly_wage": form.find('input[name="percentage_of_hourly_wage"]').val()
        }
        break;
    }
    return data;
  }

  // display response
  const displayResult = (response, calculator, calculatorId) => {
    const resultContainer = calculator.find('.sd-calculator__result');
    switch (calculatorId) {
      case "upsell_impact_calculator":
        resultContainer.find('#revenue_lift_by_implementing_dynamic_interaction')
          .text(`$${response.data.revenue_lift_by_implementing_dynamic_interaction}`);
        resultContainer.find('#incremental_upsell_revenue')
          .text(`$${response.data.incremental_up_sell_revenue}`)
        resultContainer.find('#saved_sales_from_inaccuracy')
          .text(`$${response.data.saved_sales_from_inaccuracies}`)
        break;
      case "employee_assist_calculator":
        resultContainer.find('#reduced_turnover_rate')
          .text(`${response.data.reduced_turnover_rate}%`);
        resultContainer.find('#reduced_turnover_cost')
          .text(`$${response.data.reduced_turnover_cost}`)
        break;
      case "smart_answering_calculator":
        resultContainer.find('#customer_opportunities_saved')
          .text(`${response.data.customer_opportunities_saved}`);
        resultContainer.find('#overhead_savings')
          .text(`$${response.data.overhead_savings}`)
        break;
      default:
        break;
    }
  }

  // send ajax request for data calculation
  const  sendCalculateAjax = (
    calculatorId,
    form,
    nonce,
    calculator,
    submissionId=null,
    displayResultEnable=true,
    fluentFormContent=null
  ) => {
    const formData = getFormData(calculatorId, form);
    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      dataType: "json",
      data: {
        action: 'calculate_output',
        data: formData,
        nonce: nonce,
        calculator_id: calculatorId,
        cookie_set: displayResultEnable,
        submission_id: submissionId
      },
      success: (response) => {
        if (response.success) {
          if (displayResultEnable) {
            displayResult(response, calculator, calculatorId)
            $(`#${calculatorId}`).find('.sd-calculator__result').addClass('show-results');
          } else {
            const responseData = response.data;
            setHiddenFieldValues(calculatorId, form, fluentFormContent, responseData);
          }
        }
      }
    });
  }

  // validate and format inputs 
  const validateAndFormatInput = (event) => {
    const $input = $(event.target);
    let value = $input.val();
    // Save the cursor position
    const cursorPosition = $input[0].selectionStart;
    // Remove any existing $ or % symbols
    value = value.replace(/[$%]/g, '');
    // Determine the type of the field based on its class
    if ($input.hasClass('usd-value')) {
      // Format as USD value
      if (!isNaN(value) && value.trim() !== '') {
        $input.val(`$${value}`);
      } else {
        $input.val(value); // Allow backspace to work
      }
    } else if ($input.hasClass('percentage-value')) {
      // Format as percentage value
      if (!isNaN(value) && value.trim() !== '') {
        $input.val(`${value}%`);
        // Set the cursor position before the %
        setTimeout(() => {
          $input[0].selectionStart = $input[0].selectionEnd = $input.val().length - 1;
        }, 0);
      } else {
        $input.val(value); // Allow backspace to work
      }
    } else if ($input.hasClass('numeric-value')) {
      // Validate as numeric value
      if (!isNaN(value) && value.trim() !== '') {
        $input.val(value);
      } else {
        $input.val(value); // Allow backspace to work
      }
    }
  };

  // prevent invalid user inputs
  const preventInvalidInput = (event) => {
    const $input = $(event.target);
    const value = $input.val();
    const key = event.key;
    // Allow backspace, delete, arrow keys, and tab
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
      return;
    }
    // Prevent invalid characters
    if ($input.hasClass('usd-value') || $input.hasClass('percentage-value') || $input.hasClass('numeric-value')) {
      if (!/[0-9.]/.test(key)) {
        event.preventDefault();
      }
    }
    // Allow only one decimal point
    if (key === '.' && value.includes('.')) {
      event.preventDefault();
    }
  };

  const setHiddenFieldValues = (calculatorId, form, content, calculatedValues) => {
    if (content) {
      switch (calculatorId) {
        case "upsell_impact_calculator":
          // inputs
          $(content).find('form input[name="calc_inp_avg_basket_size"]')
            .val(form.find('input[name="average_basket_size"]').val());
          $(content).find('form input[name="calc_inp_avg_service_time_secs"]')
            .val(form.find('input[name="average_service_time_in_seconds"]').val());
          $(content).find('form input[name="calc_inp_est_avg_upsell_value"]')
            .val(form.find('input[name="estimated_average_upsell_value"]').val());
          $(content).find('form input[name="calc_inp_cars_per_month_per_location"]')
            .val(form.find('input[name="cars_per_month_per_location"]').val());
          $(content).find('form input[name="calc_inp_total_locations"]')
            .val(form.find('input[name="total_number_of_locations"]').val());
          // results
          $(content).find('form input[name="calc_outp_revenue_lift"]')
            .val(`$${calculatedValues.revenue_lift_by_implementing_dynamic_interaction}`);
          $(content).find('form input[name="calc_outp_incr_upsell_revenue"]')
            .val(`$${calculatedValues.incremental_up_sell_revenue}`);
          $(content).find('form input[name="calc_outp_saved_sales_from_inaccuracy"]')
            .val(`$${calculatedValues.saved_sales_from_inaccuracies}`);
          break;
        case "employee_assist_calculator":
           // inputs
          $(content).find('form input[name="calc_inp_turnover_rate"]')
            .val(form.find('input[name="current_turnover_rate"]').val());
          $(content).find('form input[name="calc_inp_cost_turnover_empl_year"]')
            .val(form.find('input[name="average_cost_of_turnover_per_employee_year"]').val());
          // results
          $(content).find('form input[name="calc_outp_reduced_turnover_rate"]')
            .val(`${calculatedValues.reduced_turnover_rate}%`);
          $(content).find('form input[name="calc_outp_reduced_turnover_cost"]')
            .val(`$${calculatedValues.reduced_turnover_cost}`);
          break;
        case "smart_answering_calculator":
          let hourlyWage = '';
          let hourlyWagePercentage = '';
          let dedicatedPersonOnPhone = form.find('select[name="dedicated_person_on_the_phone"]').val();
          if (dedicatedPersonOnPhone == 'YES') {
            hourlyWage = form.find('input[name="hourly_wage_of_staff_member"]').val();
            hourlyWagePercentage = form.find('input[name="percentage_of_hourly_wage"]').val();
          }
          // inputs
          $(content).find('form input[name="calc_inp_daily_phone_calls"]')
            .val(form.find('input[name="average_daily_phone_calls"]').val());
          $(content).find('form input[name="calc_inp_calls_not_ans_person_day"]')
            .val(form.find('input[name="estimated_calls_not_picked_up"]').val());
          $(content).find('form input[name="calc_inp_dedicated_person_phone"]')
            .val(dedicatedPersonOnPhone);
          $(content).find('form input[name="calc_inp_hourly_staff_wage"]')
            .val(hourlyWage);
          $(content).find('form input[name="calc_inp_hourly_wage_for_phone"]')
            .val(hourlyWagePercentage);
          // results
          $(content).find('form input[name="calc_outp_opportunity_saved"]')
            .val(`${calculatedValues.customer_opportunities_saved}`);
          $(content).find('form input[name="calc_outp_overhead_savings"]')
            .val(`$${calculatedValues.overhead_savings}`);
          break;
      }
    }
  }

  // calculator submit
  $('.sd-calculator form .sd-calculator__form--submit').on('click', (e) => {
    e.preventDefault();
    const calculator = $(e.currentTarget).closest('.sd-calculator');
    const calculatorId = calculator.prop('id');
    const nonce = $(e.currentTarget).data('nonce');
    const form = calculator.find('form');
    const enablePopupForm = $(calculator).data('enable-popup');

    if (form.length) {
      if (enablePopupForm) {
        if (hasCalculatorCookieSet(calculatorId)) {
          sendCalculateAjax(calculatorId, form, nonce, calculator)
        } else {
          const content = $(calculator).find(`[data-calculator-id="${calculatorId}"]`).get(0);
          sendCalculateAjax(calculatorId, form, nonce, calculator, null, false, content);
          Fancybox.show([{
            src: content,
            type: 'inline',
            id: `${calculatorId}-fancybox`,
            classNames: `sd-calculator__popup`
          }]);
        }
      } else {
        sendCalculateAjax(calculatorId, form, nonce, calculator);
      }
    }
  });

  // hide and show dynamic fields
  $('select[name="dedicated_person_on_the_phone"]').on('change', (e) => {
    if (e.currentTarget.value === "NO") {
      $(e.currentTarget).closest('form').find('input#hourly_wage_of_staff_member, label[for="hourly_wage_of_staff_member"]').hide();
      $(e.currentTarget).closest('form').find('input#percentage_of_hourly_wage, label[for="percentage_of_hourly_wage"]').hide();
      $(e.currentTarget).closest('form').find('button.sd-calculator__form--submit').addClass('no-state');
    } else {
      $(e.currentTarget).closest('form').find('input#hourly_wage_of_staff_member, label[for="hourly_wage_of_staff_member"]').show();
      $(e.currentTarget).closest('form').find('input#percentage_of_hourly_wage, label[for="percentage_of_hourly_wage"]').show();
      $(e.currentTarget).closest('form').find('button.sd-calculator__form--submit').removeClass('no-state');
    }
  });

  // input validation and value set
  $('.sd-calculator__form form input').on('input', validateAndFormatInput);
  $('.sd-calculator__form form input').on('keydown', preventInvalidInput);

  $(document).on('fluentform_submission_success', function(e, formData, formId) {
    const calculatorPopUp = $(formData.form).closest('.sd-calculator__popup');
    if(calculatorPopUp.length) {
      const calculatorId = calculatorPopUp.data('calculator-id');
      const calculator = $(`#${calculatorId}`);
      const nonce = $(`#${calculatorId} .sd-calculator__form--submit`).data('nonce');
      const form = $(`#${calculatorId} .sd-calculator__form form`);
      const submissionId = formData.response.data.insert_id;
      sendCalculateAjax(calculatorId, form, nonce, calculator, submissionId);
      Fancybox.close(`${calculatorId}-fancybox`);
    }
  });
});