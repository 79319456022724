// eslint-disable-next-line no-undef
$(($) => {
	const header = $('header');
	let $sticky = $('.header__wrapper');
	let pos = $sticky.offset().top;
	let notifyHeight = $('.notify-bar').outerHeight();
	let $main = $('main');

	//Add Class on Scroll
	$(window).scroll(() => {
		if (window.pageYOffset >= 1) {
			header.addClass('scrolled');
		} else {
			header.removeClass('scrolled');
		}
	});

	//Add Close Button To search
	header
		.find('form.mega-search')
		.append('<span class="search-close"></span>');

	const hideSearchbar = () => {
		$('.mega-menu-item__bs-search form')
			.removeClass('mega-search-open')
			.addClass('mega-search-closed');
	};
	$(document).on('click', '.search-close', () => {
		hideSearchbar();
	});

	$(document).on('click', '.notify-bar .close-icon', () => {
		pos = $sticky.offset().top - notifyHeight;
	});

	$(function () {
		createSticky($sticky);
	});

	createSticky = (sticky) => {
		if (typeof sticky !== 'undefined') {
			const win = $(window);

			addheight = () => {
				if (win.scrollTop() >= pos) {
					sticky.addClass('fixed');
					$main.css('margin-top', $sticky.outerHeight());
				} else {
					sticky.removeClass('fixed');
					$main.css('margin-top', 0);
				}
			};
			addheight();
			win.on('scroll', function () {
				addheight();
			});
			win.on('resize', function () {
				addheight();
			});
		}
	};
});
